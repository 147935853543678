import React from "react";

import "./footer.css";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer__container container">
        <h1 className="footer__title">Gabriel Nakata</h1>

        <ul className="footer__list">
          <li>
            <a href="#about" className="footer__link">
              About
            </a>
          </li>

          <li>
            <a href="#techstack" className="footer__link">
              Tech Stack
            </a>
          </li>

          <li>
            <a href="#qualification" className="footer__link">
              Qualification
            </a>
          </li>

          <li>
            <a href="#portfolio" className="footer__link">
              Portfolio
            </a>
          </li>
        </ul>

        <div className="footer__social">
          <a
            href="https://www.linkedin.com/in/gabriel-nakata/"
            className="footer__social-link">
            <i className="uil uil-linkedin"></i>
          </a>

          <a href="https://github.com/NakkaGS" className="footer__social-link">
            <i className="uil uil-github-alt"></i>
          </a>
        </div>

        <span className="footer__copy">&#169;Gabriel Nakata - All rights reserved</span>
        
      </div>
    </footer>
  );
}
