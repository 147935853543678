import React from "react";

export default function Automation() {
  return (
    <div className="skills__content">
      <h3 className="skills__title">Automation</h3>

      <div className="skills__box">

        <div className="skills__group">
          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div>
              <h3 className="skills__name">Step 7</h3>
              <span className="skills__level">Intermediate</span>
            </div>
          </div>

          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div>
              <h3 className="skills__name">TIA Portal</h3>
              <span className="skills__level">Advanced</span>
            </div>
          </div>

          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div>
              <h3 className="skills__name">Integra 6/7 <br/><span>(Mercedes)</span></h3>
              <span className="skills__level">Advanced</span>
            </div>
          </div>

          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div>
              <h3 className="skills__name">Frequency Inverter <br/><span>(G120C / Danfoss FC280)</span></h3>
              <span className="skills__level">Intermediate</span>
            </div>
          </div>

        </div>

        <div className="skills__group">
          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div>
              <h3 className="skills__name">IDPro <br/><span>(Delphi)</span></h3>
              <span className="skills__level">Intermediate</span>
            </div>
          </div>

          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div>
              <h3 className="skills__name">STL</h3>
              <span className="skills__level">Intermediate</span>
            </div>
          </div>

          {/* <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div>
              <h3 className="skills__name">Commissioning</h3>
              <span className="skills__level">Intermediate</span>
            </div>
          </div> */}

          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div>
              <h3 className="skills__name">Studio 5000 / Compact Logix<br/><span>(Rockwell)</span></h3>
              <span className="skills__level">Intermediate</span>
            </div>
          </div>

          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div>
            <h3 className="skills__name">FactoryTalk View <br/><span>(Rockwell)</span></h3>
              <span className="skills__level">Intermediate</span>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}
