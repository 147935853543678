export const education = [
  {
    title: "Electrical Engineering",
    subtitle: "UDESC - Brazil",
    date: "5 Years (Diplom)",
    extra_info: "",
  },

  {
    title: "React Global Online Summit 2023",
    subtitle: "Geekle.us",
    date: "Mar 2023",
    extra_info: "",
  },

  {
    title: "Google Project Management Certificate",
    subtitle: "Coursera",
    date: "Jan 2023",
    extra_info: "",
  },

  {
    title: "Professional Agile Leadership I",
    subtitle: "Scrum.org",
    date: "Aug 2022",
    extra_info: "",
  },

  {
    title: "PRINCE2 Agile Practitioner Certificate",
    subtitle: "AXELOS",
    date: "Aug 2022",
    extra_info: "",
  },

  {
    title: "Become a Project Manager",
    subtitle: "LinkedIn",
    date: "Jan 2022",
    extra_info: "",
  },

];
