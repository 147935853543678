import React from "react";
import "./skills.css";

// Components
import Frontend from "./Frontend";
import Automation from "./Automation";

export default function Skills() {
  return (
    <section className="skills section" id="skills">
      <h2 className="section__title">Skills</h2>
      <span className="section__subtitle">My technical skills</span>

      <div className="skills__container container grid">
        <Frontend />
        <Automation />
      </div>
    </section>
  );
}
