import React, { useState } from "react";

import "./work.css";

import Work_1 from "../../assets/MyWorks/fiedler-konstruktion.png";
import Work_2 from "../../assets/MyWorks/lilika.png";
import Work_3 from "../../assets/MyWorks/mydashboard.png";
import Work_4 from "../../assets/MyWorks/axler-automation.png";


export default function Work() {
  const [toggleState, setToogleState] = useState(0);

  const toggleTab = (index) => {
    setToogleState(index);
  };

  return (
    <section className="work section" id="portfolio">
      <h2 className="section__title">Portfolio</h2>
      <span className="section__subtitle">My work</span>

      {/* <div className="work__filters">
        <span className="work__item active-work">All</span>
        <span className="work__item">Frontend</span>
        <span className="work__item">Backend</span>
      </div> */}

      <div className="work__container container grid">
        {/* Fiedler Konstruktion */}
        <div className="work__card">
          <img src={Work_1} alt="" className="work__img" />
          <h3 className="work__title">Fiedler Konstruktion GmbH</h3>
          <p className="work__modal-description">
            Web Design and Frontend in React
          </p>
          <ul className="work__modal-info">
            <li>
              Created - <span>12.04.2023</span>
            </li>
            <li>
              <div className="work__technlogies">
                <div className="work__category"><span>html5</span></div>
                <div className="work__category"><span>css</span></div>
                <div className="work__category"><span>reactjs</span></div>
              </div>
            </li>
            <li>
              <div className="work__visit-button">
                <a href="https://www.fiedler-konstruktion.com/">Visit</a>
                <i className="uil uil-arrow-right work__button-icon"></i>
              </div>
            </li>
          </ul>
          
        </div>
        {/* Lilika Zauberlabor */}
        <div className="work__card">
          <img src={Work_2} alt="" className="work__img" />
          <h3 className="work__title">Lilika Zauberlabor</h3>
          <h5>Under Construction</h5>
          {/* <span className="work__button" >
            View More
            <i className="uil uil-arrow-right work__button-icon"></i>
          </span> */}

          <div
            className={
              toggleState === 2 ? "work__modal active-modal" : "work__modal"
            }>
            <div className="work__modal-content grid">
              <i
                className="uil-times work__modal-close"
                onClick={() => toggleTab(0)}></i>
              <div className="work__modal-left">
                <img src={Work_2} alt="" className="work__img" />
              </div>
              <div className="work__modal-right">
                <div className="work__modal-subtitle">
                  Feature - <span>Design / Frontend</span>
                </div>
                <div className="work__modal-body">
                  <h3 className="work__modal-title">
                    Lilika Zauberlabor - Under Construction
                  </h3>
                  <p className="work__modal-description">
                    Web Design and Frontend in React
                  </p>

                  <ul className="work__modal-info">
                    <li>
                      Created - <span>04.12.2023</span>
                    </li>
                    <li>
                      Technologies - <span>html css react</span>
                    </li>
                    <li>
                      View -
                      <span>
                        <a href="#test"> www.domain.com</a>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* My Dashboard */}
        <div className="work__card">
          <img src={Work_3} alt="" className="work__img" />
          <h3 className="work__title">My Dashboard</h3>
          <p className="work__modal-description">
            Dashboard created to be used as first page on Browsers.
          </p>
          <ul className="work__modal-info">
            <li>
              Created - <span>07.10.2023</span>
            </li>
            <li>
              <div className="work__technlogies">
                <div className="work__category"><span>html5</span></div>
                <div className="work__category"><span>css</span></div>
                <div className="work__category"><span>reactjs</span></div>
              </div>
              <div className="work__technlogies">
                <div className="work__category"><span>nodejs/expressjs</span></div>
                <div className="work__category"><span>mongodb</span></div>
              </div>
            </li>
            <li>
              <div className="work__visit-button">
                <a href="https://nakkags.github.io/MyDashboard/">Visit</a>
                <i className="uil uil-arrow-right work__button-icon"></i>
              </div>
            </li>
          </ul>
          
        </div>

        {/* Axler Automation */}
        <div className="work__card">
          <img src={Work_4} alt="" className="work__img" />
          <h3 className="work__title">Axler Automation</h3>
          <p className="work__modal-description">
            Web Design and Frontend just in HTML
          </p>
          <ul className="work__modal-info">
            <li>
              Created - <span>20.10.2023</span>
            </li>
            <li>
              <div className="work__technlogies">
                <div className="work__category"><span>html5</span></div>
                <div className="work__category"><span>css</span></div>
              </div>
            </li>
            <li>
              <div className="work__visit-button">
                <a href="https://automation-axler.com/">Visit</a>
                <i className="uil uil-arrow-right work__button-icon"></i>
              </div>
            </li>
          </ul>
          
        </div>

        
      </div>
    </section>
  );
}
