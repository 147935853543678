import React, { useState } from "react";

//Styles
import "./qualification.css";

//Testimonial Data
import { education } from "../../data/education";

export default function Qualification() {
  const [toggleState, setToogleState] = useState(2);

  const toggleTab = (index) => {
    setToogleState(index);
  };

  return (
    <section className="qualification section" id="qualification">
      <h2 className="section__title">Qualification</h2>
      <span className="section__subtitle">My personal journey</span>

      <div className="qualification__container container">
        <div className="qualification__tabs">
          <div
            className={
              toggleState === 1
                ? "qualification__button qualification__active button--flex"
                : "qualification__button button--flex"
            }
            onClick={() => toggleTab(1)}>
            <i className="uil uil-graduation-cap qualification__icon"></i>{" "}
            Education
          </div>

          <div
            className={
              toggleState === 2
                ? "qualification__button qualification__active"
                : "qualification__button"
            }
            onClick={() => toggleTab(2)}>
            <i className="uil uil-briefcase-alt qualification__icon"></i>{" "}
            Experience
          </div>
        </div>

        {/* Experience */}
        <div className="qualification__sections">
          <div
            className={
              toggleState === 2
                ? "qualification__content qualification__content-active"
                : "qualification__content"
            }>

            {/* Experience 1 */}
            <div className="qualification__data">
              <div></div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>

              <div>
                <h3 className="qualification__title">PLC Programmer</h3>
                <span className="qualification__subtitle">
                  Econopak GmbH
                </span>
                <div className="qualification__calendar">
                  <i className="uil uil-calendar-alt"></i> 2023 - Actual
                </div>
                <span className="qualification__subtitle-project">
                  - PLC Programming in Tia Portal (CPU15128), S7 Classic (S7-300), and Studio 5000 (CompacLogix)
                  <br /><br />
                  - HMI creation for the program (WinCC Siemens, FactoryTalk View Rockwell)
                  <br /><br />
                  - Commissioning of the created programs and HMI in-house and at the customer's site
                  <br /><br />
                  - Design of the hardware and I/O signals
                </span>
              </div>
            </div>

            {/* Experience 2 */}
            <div className="qualification__data">
              <div>
                <h3 className="qualification__title">
                  Software Developer <br /> Commissioning
                </h3>
                <span className="qualification__subtitle">
                  Fiedler Konstruktion GmbH (Germany)
                </span>
                <div className="qualification__calendar">
                  <i className="uil uil-calendar-alt"></i> 2019 - 2023
                </div>
              </div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>

            {/* Project 1 */}
            <div className="qualification__data project">
              <div>
                <h3 className="qualification__title-project">
                  <i className="uil uil-arrow-right"></i> EB4 (Battery Line)
                  Accumotive/Mercedes Kamenz (EBZ)
                </h3>
                <span className="qualification__subtitle-project">
                  - Onsite PLC Manager
                  <br /><br />
                  - Concept development (Network, Production/NOK Concept)
                  <br /><br />
                  - Coordination of commissioning PLC/Devices
                  <br /><br />
                  - Creation of designer templates
                </span>
                <div className="qualification__calendar-project">
                  <i className="uil uil-calendar-alt"></i> May 2023 - August 2023
                </div>
              </div>

              <div>
                <span className="qualification__rounder-small"></span>
                <span className="qualification__line"></span>
              </div>
            </div>

            {/* Project 2 */}
            <div className="qualification__data project">
              <div>
                <h3 className="qualification__title-project">
                  <i className="uil uil-arrow-right"></i> BR206 Mercedes Bremen
                  (Thyssenkrupp)
                </h3>
                <span className="qualification__subtitle-project">
                  - Software Check (Standard + Safety)
                  <br /><br />
                  - Fault analysis (Body Shop Z2)
                  <br /><br />
                  - Production Support / Try Runs
                </span>
                <div className="qualification__calendar-project">
                  <i className="uil uil-calendar-alt"></i> February 2023 -
                  August 2023
                </div>
              </div>

              <div>
                <span className="qualification__rounder-small"></span>
                <span className="qualification__line"></span>
              </div>
            </div>

            {/* Project 3 */}
            <div className="qualification__data project">
              <div>
                <h3 className="qualification__title-project">
                  <i className="uil uil-arrow-right"></i> PORSCHE/SFTP WAGON
                  (EBZ)
                </h3>
                <span className="qualification__subtitle-project">
                  - Concept development
                  <br /><br />
                  - Study/Selection of Standard (BMW, SICAR, Integra)
                  <br /><br />
                  - HMI Development for Standard (WinCC and HMI Template Suite);
                  <br /><br />
                  - Support in problems related to Integra;
                  <br /><br />
                  - Concept development (Network, Production/NOK Concept)
                  <br /><br />
                  - Coordination of commissioning PLC/Devices
                  <br /><br />
                  - Creation of designer templates
                </span>
                <div className="qualification__calendar-project">
                  <i className="uil uil-calendar-alt"></i> Jun 2022 - Dec 2022
                </div>
              </div>

              <div>
                <span className="qualification__rounder-small"></span>
                <span className="qualification__line"></span>
              </div>
            </div>

            
            {/* Project 4 */}
            <div className="qualification__data project">
              <div>
                <h3 className="qualification__title-project">
                  <i className="uil uil-arrow-right"></i> MOTOR FACTORY ZF SERBIA
                  (GROB)
                </h3>
                <span className="qualification__subtitle-project">
                  - Line Optimization (Old GROB Standard)
                  <br /><br />
                  - HMI Check
                  <br /><br />
                  - Production Support
                </span>
                <div className="qualification__calendar-project">
                  <i className="uil uil-calendar-alt"></i> Mar 2022 - Mai 2022
                </div>
              </div>

              <div>
                <span className="qualification__rounder-small"></span>
                <span className="qualification__line"></span>
              </div>
            </div>

            {/* Project 5 */}
            <div className="qualification__data project">
              <div>
                <h3 className="qualification__title-project">
                  <i className="uil uil-arrow-right"></i> PB340 - BATTERY LINE VALMET
                  (GROB Mindelheim - Germany)
                </h3>
                <span className="qualification__subtitle-project">
                  - PLC programming standard (Powertrain/GROB Standard);
                  <br /><br />
                  - Line Commissioning (sensors, motors, servos):
                </span>
                <div className="qualification__calendar-project">
                  <i className="uil uil-calendar-alt"></i> Aug 2021 - Feb 2022
                </div>
              </div>

              <div>
                <span className="qualification__rounder-small"></span>
                <span className="qualification__line"></span>
              </div>
            </div>

            {/* Project 6 */}
            <div className="qualification__data project">
              <div>
                <h3 className="qualification__title-project">
                  <i className="uil uil-arrow-right"></i> PB300V/VE BLOCKBAUMODULE NEW FRONTEND (ACCUMOTIVE GMBH & CO. KG)
                  (EBZ)
                </h3>
                <span className="qualification__subtitle-project">
                  - PLC programming standard / fail-safe (Integra 6);
                  <br /><br />
                  - Coordination of commissioning and weekend tasks;
                  <br /><br />
                  - Creation of designer templates for cell system and building blocks;
                  <br /><br />
                  - Creation of worker and technical Documentation / Instructions,
                  <br /><br />
                  - Conducting training / instruction;
                  <br /><br />
                  - Implementation of subsystems (cameras (Keyence/Alpha Jet), printers (Zebra), measuring sensors, welding (Vitronic and NANO));
                  <br />
                </span>
                <div className="qualification__calendar-project">
                  <i className="uil uil-calendar-alt"></i> Jun 2022 - Dec 2022
                </div>
              </div>

              <div>
                <span className="qualification__rounder-small"></span>
                <span className="qualification__line"></span>
              </div>
            </div>

            {/* Experience 3 */}
            <div className="qualification__data">
              <div></div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>

              <div>
                <h3 className="qualification__title">PLC Programmer</h3>
                <span className="qualification__subtitle">
                  King Automation (USA)
                </span>
                <div className="qualification__calendar">
                  <i className="uil uil-calendar-alt"></i> 2020 - 2021
                </div>
                <div>
                <h3 className="qualification__title-project">
                  <i className="uil uil-arrow-right"></i> MERCEDES US BR167 TUSCALOOSA (BODYSHOP)
                  (EBZ)
                </h3>
                <span className="qualification__subtitle-project">
                - PLC programming using Step7. Bodyshop Line Support (82 PLC Plant)
                <br /><br />
                - HMI Development and Modification (Integra Design Pro)
                <br /><br />
                - Hardware Configuration: Siemens MV440 MV420 / SICK / S7-300 CPU 319F-3 Camera
                <br />
                </span>
                <div className="qualification__calendar-project">
                  <i className="uil uil-calendar-alt"></i> Jun 2022 - Dec 2022
                </div>
              </div>
              </div>
            </div>

            {/* Experience 4 */}
            <div className="qualification__data">
              <div>
                <h3 className="qualification__title">PLC Programmer</h3>
                <span className="qualification__subtitle">
                  STR Automação (Brazil)
                </span>
                <div className="qualification__calendar">
                  <i className="uil uil-calendar-alt"></i> 2018 - 2020
                </div>
                <span className="qualification__subtitle-project">
                - EPLAN analysis. 
                <br /><br />
                - TIA Portal v13.
                <br /><br />
                - BMW Spartanburg Standard Body Shop Offline Design.
                </span>
              </div>
               
              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>

              <div></div>
            </div>

            {/* Experience 5 */}
            <div className="qualification__data">
              <div></div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>

              <div>
                <h3 className="qualification__title">CAD Drafter</h3>
                <span className="qualification__subtitle">
                  GFM - Gerenciamento e Fabricação de Moldes LTDA (Brazil)
                </span>
                <div className="qualification__calendar">
                  <i className="uil uil-calendar-alt"></i> 2009 - 2010
                </div>
                <span className="qualification__subtitle-project">
                  - Part modeling. 2D and 3D drawing of mold components in AutoCAD and PowerShape. 
                  <br /><br />
                 -  Drawing review.
                </span>
              </div>
            </div>

          </div>

          {/* Education */}

          <div
            className={
              toggleState === 1
                ? "qualification__content qualification__content-active"
                : "qualification__content"
            }>

            {
              education ? (
                education.map((item, index) => {
                  return (
                    <div>
                      {index % 2 !== 0 ? (
                        <div className="qualification__data">
                          <div>
                            <h3 className="qualification__title">
                            {item.title}
                            </h3>
                            <span className="qualification__subtitle">{item.subtitle}</span>
                            <div className="qualification__calendar">
                              <i className="uil uil-calendar-alt"></i> {item.date}
                            </div>
                          </div>
            
                          <div>
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                          </div>
            
                          <div></div>
                        </div>
                      ) : (
                        <div className="qualification__data">
                          <div></div>
            
                          <div>
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                          </div>
            
                          <div>
                            <h3 className="qualification__title">
                            {item.title}
                            </h3>
                            <span className="qualification__subtitle">{item.subtitle}</span>
                            <div className="qualification__calendar">
                              <i className="uil uil-calendar-alt"></i> {item.date}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })
              ) : ""
            }

          </div>
        </div>
      </div>
    </section>
  );
}
