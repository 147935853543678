import React, { useState } from "react";
import "./header.css";

export default function Header() {
  window.addEventListener("scroll", function () {
    const header = document.querySelector(".header");

    if (this.scrollY >= 80) header.classList.add("scroll-header");
    else header.classList.remove("scroll-header");
  });

  // Toggle Menu
  const [Toggle, setToggle] = useState(false);

  return (
    <header className="header">
      <nav className="nav container">
        <a href="index.html" className="nav__logo">
          Gabriel <span>Nakata</span>
        </a>
        <div className={Toggle ? "nav__menu show-menu" : "nav__menu"}>
          <ul className="nav__list grid">
            <li className="nav__item">
              <a href="#home" className="nav__link">
                <i className="uil uil-estate nav__icon"></i> Home
              </a>
            </li>

            <li className="nav__item">
              <a href="#about" className="nav__link">
                <i className="uil uil-user nav__icon"></i> About
              </a>
            </li>

            {/* <li className="nav__item">
              <a href="#techstack" className="nav__link">
                <i className="uil uil-file-alt nav__icon"></i> Tech Stack
              </a>
            </li> */}

            <li className="nav__item">
              <a href="#qualification" className="nav__link">
                <i className="uil uil-briefcase-alt nav__icon"></i>{" "}
                Qualification
              </a>
            </li>

            <li className="nav__item">
              <a href="#portfolio" className="nav__link">
                <i className="uil uil-scenery nav__icon"></i> Portfolio
              </a>
            </li>

            {/* <li className="nav__item">
              <a href="#contact" className="nav__link">
                <i className="uil uil-message nav__icon"></i> Contact
              </a>
            </li> */}
          </ul>

          <i
            className="uil uil-times nav__close"
            onClick={() => setToggle(!Toggle)}></i>
        </div>
        <div className="nav__toggle" onClick={() => setToggle(!Toggle)}>
          <i className="uil uil-apps"></i>
        </div>
      </nav>
    </header>
  );
}
